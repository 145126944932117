<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-0">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn v-if="canEditSetupTables" class="float-right" color="green" dark @click="onSubmit"> save </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-form :readonly="value.id > 0 && !canEditSetupTables">
        <v-container>
          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="9">
              <v-text-field
                :value="value.chargeType"
                v-model.trim="$v.form.chargeType.$model"
                label="Charge Type"
                required
                :error-messages="handleErrors($v.form.chargeType)"
                @blur="$v.form.chargeType.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                :value="value.description"
                v-model.trim="$v.form.description.$model"
                label="Description"
                required
                :error-messages="handleErrors($v.form.description)"
                @blur="$v.form.description.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :value="value.amount"
                v-model.trim="$v.form.amount.$model"
                label="Amount"
                required
                type="number"
                prepend-inner-icon="mdi-currency-usd"
                :error-messages="handleErrors($v.form.amount)"
                @blur="$v.form.amount.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';

const newAdditionalInvoiceCosts = {
  chargeType: '',
  description: '',
  amount: 0,
};

export default {
  mixins: [validationMixin],
  components: {},
  data: () => ({
    handleErrors,
    id: null,
    form: newAdditionalInvoiceCosts,
    saved: false,
  }),
  validations() {
    return {
      form: {
        chargeType: { required },
        description: { required },
        amount: { required },
      },
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
  },
  methods: {
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newAdditionalInvoiceCosts;
        this.$emit('closeForm');
      }
    },
    handleInput(address) {
      this.address = address;
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.$emit('submit', { id: this.id, ...this.$v.form.$model });
      this.saved = true;
    },
    compareValues() {
      if (!this.id && this.$v.form.$model.chargeType) return true;
      if (!this.id && this.$v.form.$model.description) return true;
      if (!this.id) return false;
      if (
        this.$v.form.$model.chargeType != this.value.chargeType ||
        this.$v.form.$model.description != this.value.description ||
        this.$v.form.$model.amount != this.value.amount
      )
        return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => newAdditionalInvoiceCosts,
    },
    tripTypes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else this.form = { ...newAdditionalInvoiceCosts, ...value };
      },
    },
  },
};
</script>
